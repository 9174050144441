import React from 'react';
import './App.css';
import Grid from '@material-ui/core/Grid'

function App() {
  return (
    <div className="App">
      <Grid container
        justify="space-around"
        alignItems="center"
        style={{ maxWidth: "100%", minHeight:"100vh" }}
      >
        <Grid item
          xs={12}
        >
          <img src="./assets/elocal.png" className="App-logo" alt="logo" style={{ marginTop: "100px" }} />
        </Grid>
        <Grid item
          xs={12}
          md={6}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <a
            className="ahref"
            href="https://foro.elocal.es"
          >Ir al foro</a>
        </Grid>
        <Grid item
          xs={12}
          md={6}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <a
            className="ahref"
            href="https://get.teamviewer.com/elocal"
          >
            <img src="./assets/TW.png" alt="TW" style={{ width: "40px", paddingRight: "15px" }} />
            TeamViewer</a>
        </Grid>
        <Grid item
          xs={12}
          md={6}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <a
            href="https://aepsa.elocal.es"
          >
            <p>Aplicación Nominas GrupoH <code style={{ whiteSpace: "nowrap" }}>0.1a25</code> (No accesible)</p>
          </a>
        </Grid>
        <Grid item
          xs={12}
          md={6}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <a
            href="https://app.elocal.es"
          >
            <p>Aplicación eLocal Conecta</p>
          </a>
        </Grid>
      </Grid>

    </div>
  );
}

export default App;
